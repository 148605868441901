

export default function Education() {
    return (
        <div  className="section education">
        <span className="options-anchor" id="education"></span>
        <h2 className="title">Education</h2>
        <div className="piece">
        <div className="titles">
        <div className="first">
        <h3 className="position">Software Engineering Immersive</h3>
        <h3 className="italic">General Assembly</h3>
        </div>
        <div className="second">
        <h3>Oct 2022 - Jan 2023</h3>
        <h3 className="italic">Online</h3>
        </div>
        </div>
        </div>
        <div className="piece">
        <div className="titles">
        <div className="first">
        <h3 className="position">CELTA Pass B</h3>
        <h3 className="italic">City and Islington College</h3>
        </div>
        <div className="second">
        <h3>Sep - Dec 2018</h3>
        <h3 className="italic">London</h3>
        </div>
        </div>
        <ul>
        <li>Planned structured lessons that adhered to a string time frame.</li>
        <li>Presented in front of and supervised the learning of large groups.</li>
        <li>Delivered close analyses of student's language abilities.</li>
        </ul>
        </div >
        <div className="piece">
        <div className="titles">
        <div className="first">
        <h3 className="position">BA in English with First Class Honours</h3>
        <h3 className="italic">Queen Mary: University of London</h3>
        </div>
        <div className="second">
        <h3>Sep 2014 - Jun 2017</h3>
        <h3 className="italic">London</h3>
        </div>
        </div>
        <ul>
        <li>Analysed large amounts of text to identify key passages relevant to a given argument.</li>
        <li>Conducted independent research and produced high-quality essays of up to 10,000 words on a range of subjects.</li>
        <li>Provided structured evidence using close-reading critical analysis.</li>
        </ul>
        </div >
        </ div>
    )
}
